<template>
  <div class="justify-center">
    <div class="surface-card p-4 shadow-2 border-round w-full lg:w-6">
      <div class="text-center mb-5">
        <img
          src="images/coachera-logo-sm.png"
          alt="Image"
          height="50"
          class="mb-3"
        />
        <div class="text-900 text-3xl font-medium mb-3">Welcome Back</div>
        <span class="text-600 font-medium line-height-3"
          >Don't have an account?</span
        >
        <a
          v-on:click="redirectSignup"
          class="font-medium no-underline ml-2 text-blue-500 cursor-pointer"
          >Create one!</a
        >
      </div>

      <div>
        <label for="email1" class="block text-900 font-medium mb-2"
          >Email</label
        >
        <InputText
          id="email1"
          type="text"
          class="w-full mb-3"
          v-model="email"
        />

        <label for="password1" class="block text-900 font-medium mb-2"
          >Password</label
        >
        <InputText
          id="password1"
          type="password"
          class="w-full mb-3"
          v-model="password"
          v-on:keyup.enter="submit"
        />

        <div class="flex align-items-center justify-content-between mb-6">
          <a
            v-on:click="redirectForgotPassword"
            class="
              font-medium
              no-underline
              ml-2
              text-blue-500 text-right
              cursor-pointer
            "
            >Forgot password?</a
          >
        </div>

        <Button
          label="Sign In"
          icon="pi pi-user"
          class="w-full"
          v-on:click="submit"
        ></Button>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "../service/UserService";
export default {
  data() {
    return {
      email: "",
      password: "",
    };
  },
  userService: null,
  created() {
    this.userService = new UserService();
  },
  methods: {
    submit(event) {
      this.userService
        .loginUser({
          email: this.email,
          password: this.password,
        })
        .then((data) => {
          if ("error" in data) {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: "Wrong email or password.",
              life: 3000,
            });
          } else {
            this.$router.push("/search");
          }
        });
    },
    redirectSignup(event) {
      event.preventDefault();
      this.$router.push("/signuptype");
    },
    redirectForgotPassword(event) {
      event.preventDefault();
      this.$router.push("/forgotpassword");
    },
  },
};
</script>
